import React from 'react'
import PropTypes from 'prop-types'
import TransitionLink from 'gatsby-plugin-transition-link'

const TYPE = 'default'

const DefaultTransitionLink = ({ to, from, horizontal = false, entryDelay = 2, ...props }) => {
  return (
    <TransitionLink
      exit={{
        length: 2,
        state: {
          horizontal,
          type: TYPE,
          to,
        },
      }}
      entry={{
        delay: entryDelay,
        length: 0,
        state: {
          horizontal,
          type: TYPE,
          from,
        },
      }}
      to={to}
      {...props}
    />
  )
}

DefaultTransitionLink.propTypes = {
  to: PropTypes.string.isRequired, // which url to go to (also passed as transition state)
  from: PropTypes.string, // (optional) which url you came from, passed as transition state)
  horizontal: PropTypes.bool, // (optional) which transition direction
  entryDelay: PropTypes.number, // when using footer links, delay needs to be shorter
}

DefaultTransitionLink.type = TYPE

export default DefaultTransitionLink
