import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { TransitionState } from 'gatsby-plugin-transition-link'

import ViewportEnter from 'components/motion/ViewportEnter'

import s from './Section.module.scss'
const cn = classNames.bind(s)

const Section = forwardRef(
  (
    {
      className,
      as = 'section',
      transitionOut = false,
      fillHeight,
      fullHeight,
      onEnter,
      onExit,
      hideOnMobile = false,
      once = true,
      isHidden = false,
      ...props
    },
    ref,
  ) => {
    const Element = as
    const [inView, setInView] = useState(false)

    const _onEnter = () => {
      setInView(true)
      onEnter && onEnter()
    }
    const _onExit = () => {
      setInView(false)
      onExit && onExit()
    }

    return (
      <TransitionState>
        {({ mount, transitionStatus, current } = {}) => {
          // transition out if in view
          const exiting = inView && transitionOut && transitionStatus === 'exiting'
          return (
            <ViewportEnter onEnter={_onEnter} onExit={_onExit} once={once}>
              <Element
                ref={ref}
                className={cn('section', className, { exiting, fillHeight, fullHeight, hideOnMobile, isHidden })}
                {...props}
              />
            </ViewportEnter>
          )
        }}
      </TransitionState>
    )
  },
)

Section.displayName = 'Section'

Section.propTypes = {
  as: PropTypes.string,
  transitionOut: PropTypes.bool,
  fillHeight: PropTypes.bool, // 70vh-ish
  fullHeight: PropTypes.bool, // 100vh
  onExit: PropTypes.func,
  onEnter: PropTypes.func,
  once: PropTypes.bool,
  hideOnMobile: PropTypes.bool,
  isHidden: PropTypes.bool,
}

export default Section
