import React, { useMemo } from 'react'
import { useLocation } from '@reach/router'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'

import { PrismicLink } from 'prismic'

import { BodyCopy } from 'components/ui/Typography'

import DefaultTransitionLink from 'components/motion/DefaultTransitionLink'

import s from './InlineMenu.module.scss'
const cn = classNames.bind(s)

const InlineMenu = ({ links }) => {
  const location = useLocation()

  const activeLink = useMemo(() => {
    return links.find(({ link }) => {
      return PrismicLink.url(link) === location.pathname
    })
  }, [location])

  return (
    <nav className={cn('inlineMenu')}>
      <ul className={cn('links')}>
        {links.map(({ label, link }, i) => {
          return (
            <BodyCopy as="li" key={link._meta.uid + i} className={cn('link')}>
              <DefaultTransitionLink
                className={cn('link', { isActive: activeLink?.link === link })}
                to={PrismicLink.url(link)}
                horizontal={link._meta.uid.includes('work') || link._meta.uid.includes('originals')}
              >
                <span className={cn('linkText')}>{label}</span>
              </DefaultTransitionLink>
            </BodyCopy>
          )
        })}
      </ul>
    </nav>
  )
}

export default InlineMenu

InlineMenu.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.shape({
        _meta: PropTypes.shape({
          uid: PropTypes.string,
        }),
      }),
    }),
  ),
}
