import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import ShapeCircle from 'assets/svg/menu-shape-circle.inline.svg'
import ShapeRectangle from 'assets/svg/menu-shape-rectangle.inline.svg'
import ShapeTriangle from 'assets/svg/menu-shape-triangle.inline.svg'
import ShapePause from 'assets/svg/menu-shape-pause.inline.svg'
import ShapeRewind from 'assets/svg/menu-shape-rewind.inline.svg'

import s from './Shape.module.scss'
const cn = classNames.bind(s)

/* eslint-disable react/display-name */
const COMPONENTS_BY_SHAPE = {
  circle: (props) => <ShapeCircle {...props} />,
  rectangle: (props) => <ShapeRectangle {...props} />,
  triangle: (props) => <ShapeTriangle {...props} />,
  pause: (props) => <ShapePause {...props} />,
  rewind: (props) => <ShapeRewind {...props} />,
  rotatingRewind: (props) => <ShapeRewind {...props} />,
}
/* eslint-enable react/display-name */

const ShapeComponent = ({ shape, className }) =>
  COMPONENTS_BY_SHAPE?.[shape]?.({ className: cn('shape', shape, className) }) || null

const Shapes = ({ shape, containerClassName, className }) => (
  <span className={cn('linkShape', containerClassName)}>
    <ShapeComponent shape={shape} className={cn(className)} />
  </span>
)

Shapes.propTypes = {
  shape: PropTypes.string,
  containerClassName: PropTypes.string,
  className: PropTypes.string,
}

export default Shapes
