import React, { useRef, useEffect } from 'react'
import classNames from 'classnames/bind'
import { motion, AnimatePresence } from 'framer-motion'

import LoadingCursorIcon from 'assets/svg/cursor-loading.inline.svg'
import DefaultCursorIcon from 'assets/svg/cursor-default.inline.svg'
import CloseIcon from 'assets/svg/menu-close.inline.svg'
import MuteIcon from 'assets/svg/video-controls-mute.inline.svg'
import PauseIcon from 'assets/svg/video-controls-pause.inline.svg'
import PlayIcon from 'assets/svg/video-controls-play.inline.svg'
import RecordIcon from 'assets/svg/video-controls-record.inline.svg'
import UnmuteIcon from 'assets/svg/video-controls-unmute.inline.svg'
import ForwardIcon from 'assets/svg/video-controls-forward.inline.svg'
import RewindIcon from 'assets/svg/video-controls-rewind.inline.svg'
import ExpandIcon from 'assets/svg/video-controls-expand.inline.svg'
import ShrinkIcon from 'assets/svg/video-controls-shrink.inline.svg'
import useWindowFocus from 'lib/useWindowFocus'
import useUIContext from 'context/ui'
import Portal from 'components/ui/Portal'

import s from './Cursor.module.scss'
const cn = classNames.bind(s)

export const CURSOR_ICONS = {
  LOADING: 'LOADING',
  CLOSE: 'CLOSE',
  VIDEO_CONTROLS_MUTE: 'VIDEO_CONTROLS_MUTE',
  VIDEO_CONTROLS_PAUSE: 'VIDEO_CONTROLS_PAUSE',
  VIDEO_CONTROLS_PLAY: 'VIDEO_CONTROLS_PLAY',
  VIDEO_CONTROLS_RECORD: 'VIDEO_CONTROLS_RECORD',
  VIDEO_CONTROLS_UNMUTE: 'VIDEO_CONTROLS_UNMUTE',
  VIDEO_CONTROLS_FORWARD: 'VIDEO_CONTROLS_FORWARD',
  VIDEO_CONTROLS_REWIND: 'VIDEO_CONTROLS_REWIND',
  VIDEO_CONTROLS_EXPAND: 'VIDEO_CONTROLS_EXPAND',
  VIDEO_CONTROLS_SHRINK: 'VIDEO_CONTROLS_SHRINK',
}

const ICON_COMPONENTS = {
  LOADING: LoadingCursorIcon,
  CLOSE: CloseIcon,
  VIDEO_CONTROLS_MUTE: MuteIcon,
  VIDEO_CONTROLS_PAUSE: PauseIcon,
  VIDEO_CONTROLS_PLAY: PlayIcon,
  VIDEO_CONTROLS_RECORD: RecordIcon,
  VIDEO_CONTROLS_UNMUTE: UnmuteIcon,
  VIDEO_CONTROLS_FORWARD: ForwardIcon,
  VIDEO_CONTROLS_REWIND: RewindIcon,
  VIDEO_CONTROLS_EXPAND: ExpandIcon,
  VIDEO_CONTROLS_SHRINK: ShrinkIcon,
}

const LERP_AMOUNT = 0.5

const lerp = (v0, v1, t) => v0 * (1 - t) + v1 * t

const lerp2d = ([v0x, v0y], [v1x, v1y], t) => [lerp(v0x, v1x, t), lerp(v0y, v1y, t)]

const getDistance = (a, b) => Math.sqrt(a.reduce((acc, _, n) => acc + (a[n] - b[n]) ** 2, 0))

const Cursor = () => {
  const isWindowFocused = useWindowFocus()
  const cursorIcon = useUIContext((s) => s.cursorIcon)
  const isPageTransitioning = useUIContext((s) => s.isPageTransitioning)

  // elements
  const cursorRef = useRef()

  // values
  const mouseAnimationFrame = useRef()
  const mouse = useRef([0.5, 0.5])
  const mouseDelta = useRef([0.5, 0.5])

  const CustomIcon = isPageTransitioning ? ICON_COMPONENTS.LOADING : ICON_COMPONENTS?.[cursorIcon]

  const isVisible = isWindowFocused

  const render = () => {
    if (!cursorRef.current) {
      window.cancelAnimationFrame(mouseAnimationFrame.current)
      mouseAnimationFrame.current = null
      return
    }
    // calculate lerped values
    mouseDelta.current = lerp2d(mouseDelta.current, mouse.current, LERP_AMOUNT)

    // setting css variables
    cursorRef.current.style.setProperty('--mouse-x', (mouseDelta.current[0] - 0.5) * 100 + 'vw')
    cursorRef.current.style.setProperty('--mouse-y', (mouseDelta.current[1] - 0.5) * 100 + 'vh')

    // cancel loop if mouse stops moving
    if (getDistance(mouseDelta.current, mouse.current) < 0.001) {
      window.cancelAnimationFrame(mouseAnimationFrame.current)
      mouseAnimationFrame.current = null
      return
    }

    // or continue looping if mouse is moving
    mouseAnimationFrame.current = window.requestAnimationFrame(render)
  }

  const onMouseMove = ({ clientX, clientY }) => {
    mouse.current = [clientX / window.innerWidth, clientY / window.innerHeight]
    // trigger loop if no loop is active
    if (mouseAnimationFrame.current) return
    mouseAnimationFrame.current = window.requestAnimationFrame(render)
  }

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove, { once: false })
    mouseAnimationFrame.current = window.requestAnimationFrame(render)
    return () => {
      window.removeEventListener('mousemove', onMouseMove, { once: false })
      mouseAnimationFrame.current = null
    }
  }, [])

  return (
    <Portal root="cursor">
      <div className={cn('area')}>
        <div className={cn('cursorPosition')} ref={cursorRef}>
          <div className={cn('cursorVisibility')} data-visible={isVisible}>
            <div className={cn('cursorOverflow')} data-expanded={!!CustomIcon}>
              <div className={cn('defaultIcon')}>
                <DefaultCursorIcon />
              </div>
              <div className={cn('customIcon')}>
                <AnimatePresence>
                  {!!CustomIcon && (
                    <motion.div
                      key={cursorIcon}
                      initial={{
                        opacity: 0,
                        scale: 1.05,
                      }}
                      animate={{
                        opacity: 1,
                        scale: 1,
                        transition: {
                          type: 'tween',
                        },
                      }}
                      exit={{
                        scale: 0.9,
                        opacity: 0,
                        transition: {
                          type: 'tween',
                        },
                      }}
                    >
                      <CustomIcon height={32} width={32} />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  )
}

const PointerOnlyCursor = () => {
  const isPointerPrimaryInput = useUIContext((s) => s.isPointerPrimaryInput)
  if (!isPointerPrimaryInput) return null
  return <Cursor />
}

export default PointerOnlyCursor
