import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'prismic-reactjs'
import { linkResolver } from './link-resolver'

import Clickable from 'components/ui/Clickable'

const PrismicLink = ({ to, ...props }) => {
  let target = {}
  if (to && to.target) {
    target = {
      target: to.target,
      rel: 'noopener',
    }
  }

  return <Clickable to={PrismicLink.url(to)} {...target} {...props} />
}

PrismicLink.propTypes = {
  to: PropTypes.any,
}

PrismicLink.url = (data) => {
  // grab type from _meta if not available on the document data
  return Link.url(data?.type ? data : { ...data, ...data?._meta }, linkResolver)
}

export default PrismicLink
