import React, { useState } from 'react'
import classNames from 'classnames/bind'

import ViewportEnter from 'components/motion/ViewportEnter'

import s from './SiteLoader.module.scss'
const cn = classNames.bind(s)

const _ = {
  phrase: ['Make', 'A', 'Difference'],
  lines: 7,
}

const range = (n) => [...Array(n).keys()]

let buffer = 0

const SiteLoader = () => {
  const [isInView, setIsInView] = useState(false)

  return (
    <ViewportEnter onEnter={() => setIsInView(true)}>
      <div className={cn('wrapper', { isInView })}>
        <div
          className={cn('bg')}
          onAnimationStart={() => {
            document.documentElement.classList.add('loader-done')
          }}
        />
        <div className={cn('phrase')}>
          {_.phrase.map((part, i) => (
            <span key={i} className={cn('part')}>
              <span className={cn('text')}>{part}</span>
            </span>
          ))}
        </div>
        <div className={cn('lines')}>
          {range(_.lines).map((line, index) => (
            <div
              className={cn('line')}
              key={index}
              onAnimationEnd={() => _.lines === ++buffer && document.documentElement.classList.add('loader-removed')}
            />
          ))}
        </div>
      </div>
    </ViewportEnter>
  )
}

export default SiteLoader
