import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import s from './Typography.module.scss'
const cn = classNames.bind(s)

// HEADLINE HERO (SemiBold)
export const HeadlineHero = ({ className, as = 'h3', ...props }) => {
  const Element = as
  return <Element className={cn('headlinehero', className)} {...props} />
}

HeadlineHero.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
}

// HEADLINE 1 (SemiBold)
export const Headline1 = ({ className, as = 'h1', ...props }) => {
  const Element = as
  return <Element className={cn('headline1', className)} {...props} />
}

Headline1.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
}

// HEADLINE 2 (SemiBold)
export const Headline2 = ({ className, as = 'h2', ...props }) => {
  const Element = as
  return <Element className={cn('headline2', className)} {...props} />
}

Headline2.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
}

// HEADLINE 3 (SemiBold)
export const Headline3 = ({ className, as = 'h3', ...props }) => {
  const Element = as
  return <Element className={cn('headline3', className)} {...props} />
}

Headline3.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
}

// HEADLINE 4 (SemiBold)
export const Headline4 = ({ className, as = 'h4', ...props }) => {
  const Element = as
  return <Element className={cn('headline4', className)} {...props} />
}

Headline4.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
}

// SUBTITLE (Regular)
export const Subtitle = ({ className, as = 'h3', vertical, children, ...props }) => {
  const Element = as
  return (
    <Element className={cn('subtitle', className, vertical && 'vertical')} {...props}>
      <span className={cn('inner')}>{children}</span>{' '}
    </Element>
  )
}

Subtitle.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
  vertical: PropTypes.bool,
}

// BODY COPY (SemiBold, Medium variants)
export const BodyCopy = ({ className, as = 'p', semiBold = false, ...props }) => {
  const Element = as
  return <Element className={cn('bodyCopy', className, semiBold && 'semiBold')} {...props} />
}

BodyCopy.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
  semiBold: PropTypes.bool,
}
