const React = require('react')
const { Link, Elements } = require('prismic-reactjs')
const { Headline4, Subtitle, BodyCopy } = require('../components/ui/Typography')
const Clickable = require('components/ui/Clickable').default
const { linkResolver } = require('./link-resolver')

const htmlSerializer = (type, element, content, children, index) => {
  const key = (content || '') + index
  if (type === Elements.heading4) return <Headline4 key={key}>{children}</Headline4>
  if (type === Elements.heading5) return <Subtitle key={key}>{children}</Subtitle>
  if (type === Elements.paragraph) return <BodyCopy key={key}>{children}</BodyCopy>
  if (type === Elements.hyperlink) {
    // Generate links to Prismic Documents as <Button> components
    const url = Link.url(element.data, linkResolver)
    const { target } = element.data
    const targetProps = target ? { target, rel: 'noopener' } : {}

    return (
      <Clickable to={url} className="plain" {...targetProps} key={key}>
        {content}
      </Clickable>
    )
  }

  // Return null to stick with the default behavior for everything else
  return null
}

module.exports = { htmlSerializer }
