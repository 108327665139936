/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react'
import queryString from 'query-string'
import smoothscroll from 'smoothscroll-polyfill'

import 'wipe.css'
import 'styles/styles.scss'

import { UISideEffects } from 'context/ui'

import Header from 'components/ui/Header'
import FullscreenMenu from 'components/ui/FullscreenMenu'
import Background from 'components/ui/Background'
import Cursor from 'components/ui/Cursor'
import SiteLoader from 'components/ui/SiteLoader'

const { registerLinkResolver } = require('gatsby-source-prismic-graphql')
const { linkResolver } = require('./src/prismic/link-resolver')

registerLinkResolver(linkResolver)
smoothscroll.polyfill()

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return (
    <>
      <UISideEffects />
      {element}
    </>
  )
}

// eslint-disable-next-line react/prop-types
export const wrapPageElement = ({ element }) => {
  return (
    <>
      <SiteLoader />
      <Header />
      {element}
      <FullscreenMenu />
      <Background />
      <Cursor />
    </>
  )
}

// After ReactDOM.render has executed
export const onInitialClientRender = () => {
  const qs = queryString.parse(window.location.search)

  // show FPS counter?
  if (typeof qs.fps !== 'undefined') {
    const script = document.createElement('script')
    script.onload = function () {
      // eslint-disable-next-line no-undef
      const stats = new Stats()
      document.body.appendChild(stats.dom)
      window.requestAnimationFrame(function loop() {
        stats.update()
        window.requestAnimationFrame(loop)
      })
    }
    script.src = '//mrdoob.github.io/stats.js/build/stats.min.js'
    document.head.appendChild(script)
  }

  if (typeof qs.skipLoader !== 'undefined') {
    document.documentElement.classList.add('loader-skipped')
    document.documentElement.classList.add('loader-done')
  }
}

// Adding rendering tracking utility to be used as ComponentName.whyDidYouRender = true
// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render')
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   })
// }
