import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import getComputedMatrix from 'lib/getComputedMatrix'
import DefaultTransitionLink from 'components/motion/DefaultTransitionLink'

import s from './Clickable.module.scss'

const cn = classNames.bind(s)

const Clickable = ({ to, children, className, entryDelay, ...p }) => {
  const isText = typeof children === 'string'
  const href = (to || {}).pathname || typeof to === 'string' ? to : null

  const props = {
    className: cn('clickable', className, { underline: isText }),
    children,
  }

  const onInternalAnchorClick = (e) => {
    e.preventDefault()
    const targetHash = e?.currentTarget?.href?.split('#')[1]
    const targetEl = targetHash && document.getElementById(targetHash)
    if (!targetEl) return
    const bounds = targetEl.getBoundingClientRect()
    const matrix = getComputedMatrix(targetEl)
    window.scrollTo(0, bounds.top - matrix.translateY, { behaviour: 'smooth' })
  }

  // render <button /> if no href passed
  if (!href) return <button type={p.type || 'button'} {...p} {...props} />

  // internal anchor (starts with # or the with the bizarre prismic output https://#)
  if (/^(#|https:\/\/#)/.test(href)) return <a onClick={onInternalAnchorClick} {...p} {...props} href={href} />

  // internal (starts with /)
  if (/^\//.test(href)) return <DefaultTransitionLink {...p} {...props} entryDelay={entryDelay} to={href} />

  // known external (starts with http or mailto)
  if (/^(http|mailto)/.test(href)) return <a {...p} {...props} href={href} target="_blank" rel="noopener noreferrer" />

  // fallback
  return <a {...p} {...props} href={href} />
}

Clickable.propTypes = {
  to: PropTypes.string,
  target: PropTypes.string,
  disabled: PropTypes.bool,
  activeClassName: PropTypes.string,
  style: PropTypes.object,
  entryDelay: PropTypes.number,
}

export default Clickable
