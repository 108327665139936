import React, { useCallback, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import classNames from 'classnames/bind'

import useUIContext from 'context/ui'
// import { linkResolver } from 'prismic/link-resolver'
import { PrismicLink } from 'prismic'

import HeaderLogo from 'assets/svg/header-logo.inline.svg'
import MenuClose from 'assets/svg/menu-close.inline.svg'

import { Container } from 'components/layout'
import ScrollLock from 'components/layout/ScrollLock'

import Portal from 'components/ui/Portal'
import { Headline3 } from 'components/ui/Typography'
import { CURSOR_ICONS } from 'components/ui/Cursor'
import CursorHover from 'components/ui/CursorHover'
import Shape from 'components/ui/Shape'

import DefaultTransitionLink from 'components/motion/DefaultTransitionLink'

import s from './FullscreenMenu.module.scss'
const cn = classNames.bind(s)

const query = graphql`
  query Menu {
    prismic {
      site_settings(uid: "site-settings", lang: "en-us") {
        menu_label
        header_links {
          label
          link {
            ...PrismicLinkFrag
          }
        }
      }
    }
  }
`
const SHAPES = ['circle', 'rectangle', 'triangle', 'rotatingRewind', 'pause']

const _ = {
  delay: 1500,
}

const FullscreenMenu = () => {
  const { prismic } = useStaticQuery(query)
  const links = prismic?.site_settings?.header_links || []
  const isMenuOpen = useUIContext((s) => s.isMenuOpen)
  const toggleMenu = useUIContext((s) => s.toggleMenu)
  const setShowDefaultLoader = useUIContext((s) => s.setShowDefaultLoader)
  const tabIndex = undefined
  const [hideLinks, setHideLinks] = useState(false)

  const onLinkClick = useCallback(() => {
    setHideLinks(true)
    // ¯\_(ツ)_/¯
    setShowDefaultLoader(true)
    setTimeout(() => {
      toggleMenu(false)
      setHideLinks(false)
    }, _.delay)
  }, [])

  return (
    <Portal>
      <ScrollLock lock={isMenuOpen} />
      <div className={cn('backdrop', { isMenuOpen })} />
      <CursorHover icon={CURSOR_ICONS.CLOSE}>
        <button
          tabIndex={tabIndex}
          onClick={() => toggleMenu(false)}
          aria-label="close menu"
          className={cn('closeArea', { isMenuOpen })}
        />
      </CursorHover>
      <Container className={cn('wrapper', { isMenuOpen })}>
        <div className={cn('topBar')}>
          <DefaultTransitionLink
            to="/"
            className={cn('logo', { isMenuOpen, hideLinks })}
            tabIndex={tabIndex}
            aria-label="home"
            onClick={() => {
              isMenuOpen && onLinkClick()
            }}
          >
            <div className={cn('logoWrapper')}>
              <HeaderLogo />
            </div>
          </DefaultTransitionLink>
          <div className={cn('close', { isMenuOpen })}>
            <MenuClose />
          </div>
        </div>
        <ul className={cn('links')}>
          {links.map(({ label, link }, i) => (
            <Headline3
              as="li"
              key={link + i}
              className={cn('link', { isMenuOpen, hideLinks })}
              style={{ '--transition-delay': isMenuOpen ? `${i * 125}ms` : `0ms` }}
            >
              <DefaultTransitionLink
                className={cn('linkAnchor')}
                to={PrismicLink.url(link)}
                tabIndex={tabIndex}
                onClick={onLinkClick}
                horizontal={link._meta.uid.includes('work') || link._meta.uid.includes('originals')}
              >
                <Shape className={cn('shape')} containerClassName={cn('linkShape')} shape={SHAPES[i]} />
                <span className={cn('linkText', { isMenuOpen })}>{label}</span>
              </DefaultTransitionLink>
            </Headline3>
          ))}
        </ul>
      </Container>
    </Portal>
  )
}

export default FullscreenMenu
