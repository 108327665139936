import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import s from './Container.module.scss'
const cn = classNames.bind(s)

const Container = ({ className, as = 'div', flex = false, ...props }) => {
  const Element = as
  return <Element className={cn('container', className, { flex })} {...props} />
}

Container.propTypes = {
  as: PropTypes.string,
  flex: PropTypes.bool,
}

export default Container
