import React from 'react'
import { RichText as PrismicRichText } from 'prismic-reactjs'

const { linkResolver } = require('./link-resolver')
const { htmlSerializer } = require('./html-serializer')

export const RichText = (props) => {
  return <PrismicRichText linkResolver={linkResolver} htmlSerializer={htmlSerializer} {...props} />
}

RichText.render = (data) => PrismicRichText.render(data, linkResolver, htmlSerializer)
RichText.asText = (data) => PrismicRichText.asText(data)

export default RichText
