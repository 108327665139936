import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import classNames from 'classnames/bind'
import Headroom from 'react-headroom'

import useUIContext from 'context/ui'

import Container from 'components/layout/Container'

import { CURSOR_ICONS } from 'components/ui/Cursor'
import CursorHover from 'components/ui/CursorHover'
import Clickable from 'components/ui/Clickable'
import InlineMenu from 'components/ui/InlineMenu'

import DefaultTransitionLink from 'components/motion/DefaultTransitionLink'

import HeaderLogo from 'assets/svg/header-logo.inline.svg'

import s from './Header.module.scss'
const cn = classNames.bind(s)

const query = graphql`
  query Header {
    prismic {
      site_settings(uid: "site-settings", lang: "en-us") {
        menu_label
        header_links {
          label
          link {
            ...PrismicLinkFrag
            ... on PRISMIC__ExternalLink {
              url
            }
          }
        }
      }
    }
  }
`

const HeaderContent = ({ prismic }) => {
  const data = prismic?.site_settings || {}
  const isMenuOpen = useUIContext((s) => s.isMenuOpen)
  const toggleMenu = useUIContext((s) => s.toggleMenu)
  const isLogoDark = useUIContext((s) => s.isLogoDark)
  const links = prismic?.site_settings?.header_links || []

  return (
    <>
      <Container className={s.main}>
        <DefaultTransitionLink to="/" className={cn(s.link, s.logoLink)} aria-label="home">
          <div className={cn('logo', { isMenuOpen })} data-theme={isLogoDark ? 'light' : undefined}>
            <HeaderLogo />
          </div>
        </DefaultTransitionLink>
        <CursorHover icon={CURSOR_ICONS.VIDEO_CONTROLS_RECORD}>
          <span className={cn('menuOpener', 'bodyCopy', { isMenuOpen })}>
            <Clickable
              className={cn(s.link, s.menuLink, { isMenuOpen })}
              onClick={() => toggleMenu(true)}
              aria-label="open menu"
            >
              {data.menu_label}
            </Clickable>
          </span>
        </CursorHover>
        {!!links && links.length && <InlineMenu links={links} />}
      </Container>
    </>
  )
}

const HeaderContentWithData = (props) => (
  <StaticQuery
    query={`${query}`} // workaround proptype error: https://github.com/birkir/gatsby-source-prismic-graphql/issues/70#issuecomment-572779682
    // withPreview forces component to re-mount on navigation
    // which is not acceptable for page transitions
    render={(data) => {
      return <HeaderContent {...data} {...props} />
    }}
  />
)

// Wrap static query in element that is persistent
const Header = (props) => {
  const isHeaderHidden = useUIContext((s) => s.isHeaderHidden)
  return (
    <header className={cn('header', { isHeaderHidden })}>
      <Headroom className={s.headroomWrapper} disableInlineStyles={true}>
        <HeaderContentWithData {...props} />
      </Headroom>
    </header>
  )
}

export default Header
