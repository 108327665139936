import React from 'react'
import classNames from 'classnames/bind'

import useUIContext from 'context/ui'

import s from './Background.module.scss'
const cn = classNames.bind(s)

const Background = () => {
  const shouldAnimatePageBg = useUIContext((s) => s.shouldAnimatePageBg)
  const setShouldAnimatePageBg = useUIContext((s) => s.setShouldAnimatePageBg)

  return (
    <div
      className={cn('background', { animate: shouldAnimatePageBg })}
      onTransitionEnd={() => {
        setShouldAnimatePageBg(false)
      }}
    >
      <div className={cn('light')} />
      <div className={cn('dark')} />
    </div>
  )
}

export default Background
