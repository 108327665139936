/* eslint-disable react/no-unused-state */
import { useEffect, useRef, useLayoutEffect } from 'react'
import { useWindowWidth } from '@react-hook/window-size'
import create from 'zustand'
import { motionValue } from 'framer-motion'

import { desktop } from 'styles/config/_breakpoints.scss'

const reduceObject = (obj = {}) => (...args) => Object.entries(obj).reduce(...args)

const waypointViewportReducer = (w) => (acc, [key, value]) => ({
  ...acc,
  [key]: reduceObject(acc?.[key])((a, [k, v]) => {
    if (v.viewportWidth !== w) return a
    return { ...a, [k]: v }
  }, {}),
})

const useUIContext = create((set, get) => ({
  isPointerPrimaryInput: false,
  setIsPointerPrimaryInput: (isPointerPrimaryInput) => set({ isPointerPrimaryInput }),
  checkIsPointerPrimaryInput: () => {
    if (typeof window === 'undefined') return true
    return window.matchMedia('(hover: hover) and (pointer: fine)').matches
  },

  isMenuOpen: false,
  toggleMenu: (isMenuOpen) => set({ isMenuOpen }),

  isLogoDark: false,
  setDarkLogo: (isLogoDark) => set({ isLogoDark }),

  isHeaderHidden: false,
  toggleHiddenHeader: (isHeaderHidden) => set({ isHeaderHidden }),

  scrollY: motionValue(0),
  scrollX: motionValue(0),

  // -1 downwards, 1 upwards
  scrollDirection: null,
  setScrollDirection: (scrollDirection) => set({ scrollDirection }),

  // 0 to 1 value (supported: x, y on mobile)
  scrollProgress: motionValue(0),

  usingHorizontalScroll: 0,
  requestHorizontalScroll: () => set((state) => ({ usingHorizontalScroll: state.usingHorizontalScroll + 1 })),
  releaseHorizontalScroll: () =>
    set((state) => ({ usingHorizontalScroll: Math.max(0, state.usingHorizontalScroll - 1) })),

  isHorizontalScrollActive: false,
  setIsHorizontalScrollActive: (isHorizontalScrollActive) => set({ isHorizontalScrollActive }),

  usingVerticalScroll: 0,
  requestVerticalScroll: () => set((state) => ({ usingVerticalScroll: state.usingVerticalScroll + 1 })),
  releaseVerticalScroll: () => set((state) => ({ usingVerticalScroll: Math.max(0, state.usingVerticalScroll - 1) })),

  currentLayout: 'vertical',
  setCurrentLayout: (currentLayout) => set({ currentLayout }),

  isFontLoaded: false,
  setIsFontLoaded: (isFontLoaded) => set({ isFontLoaded }),

  pageTheme: null,
  setPageTheme: (pageTheme) => set({ pageTheme }),

  currentTheme: null,
  setCurrentTheme: (currentTheme) => set({ currentTheme }),

  preventThemeChange: false,
  setPreventThemeChange: (preventThemeChange) => set({ preventThemeChange }),

  shouldAnimatePageBg: false,
  setShouldAnimatePageBg: (shouldAnimatePageBg) => set({ shouldAnimatePageBg }),

  cursorIcon: null,
  setCursorIcon: (cursorIcon) => set({ cursorIcon }),

  isPageTransitioning: null,
  setIsPageTransitioning: (isPageTransitioning) => set({ isPageTransitioning }),

  showDefaultLoader: null,
  setShowDefaultLoader: (showDefaultLoader) => set({ showDefaultLoader }),

  waypoints: {},

  registerWaypoint: (key, id, waypoint) =>
    set(({ waypoints }) => ({
      waypoints: {
        ...waypoints,
        [key]: { ...(waypoints?.[key] || {}), [id]: waypoint },
      },
    })),

  clearWaypoints: () => set({ waypoints: {} }),

  removeWaypointKey: (key) =>
    set(({ waypoints }) => {
      const { [key]: _, ...rest } = waypoints || {}
      return { waypoints: rest }
    }),

  removeWaypointKeyId: (key, id) =>
    set(({ waypoints }) => {
      const { [id]: _, ...rest } = waypoints?.[key] || {}
      return {
        waypoints: {
          ...waypoints,
          [key]: rest,
        },
      }
    }),

  updateWaypointViewport: (w) =>
    set(({ waypoints }) => ({ waypoints: reduceObject(waypoints)(waypointViewportReducer(w), {}) })),
}))

export default useUIContext

export const UISideEffects = () => {
  const lastUsingVertical = useRef(0)
  const lastUsingHorizontal = useRef(0)
  const setIsPointerPrimaryInput = useUIContext((s) => s.setIsPointerPrimaryInput)
  const checkIsPointerPrimaryInput = useUIContext((s) => s.checkIsPointerPrimaryInput)
  const scrollY = useUIContext((s) => s.scrollY)
  const scrollX = useUIContext((s) => s.scrollX)
  const pageTheme = useUIContext((s) => s.pageTheme)
  const currentTheme = useUIContext((s) => s.currentTheme)
  const preventThemeChange = useUIContext((s) => s.preventThemeChange)
  const setShouldAnimatePageBg = useUIContext((s) => s.setShouldAnimatePageBg)
  const viewportWidth = useWindowWidth()

  const usingHorizontalScroll = useUIContext((s) => s.usingHorizontalScroll)
  const usingVerticalScroll = useUIContext((s) => s.usingVerticalScroll)

  const currentLayout = useUIContext((s) => s.currentLayout)
  const setCurrentLayout = useUIContext((s) => s.setCurrentLayout)

  // const isHorizontalScrollActive = useUIContext((s) => s.isHorizontalScrollActive)
  const setIsHorizontalScrollActive = useUIContext((s) => s.setIsHorizontalScrollActive)

  const theme = currentTheme || pageTheme

  useLayoutEffect(() => {
    if (usingHorizontalScroll) return
    setIsHorizontalScrollActive(false)
    return () => {
      setIsHorizontalScrollActive(false)
    }
  }, [usingHorizontalScroll, setIsHorizontalScrollActive])

  // useEffect(() => {
  //   console.log('-'.repeat(100))
  //   console.log({ currentLayout, isHorizontalScrollActive, usingHorizontalScroll, usingVerticalScroll })
  //   console.log('-'.repeat(100))
  // }, [currentLayout, isHorizontalScrollActive, usingHorizontalScroll, usingVerticalScroll])

  useLayoutEffect(() => {
    setIsPointerPrimaryInput(checkIsPointerPrimaryInput())
  }, [viewportWidth])

  useEffect(() => {
    // make sure we get correct scroll pos if reloading when scrolled down
    scrollY.set(window.pageYOffset)
    scrollX.set(window.pageXOffset)
  }, [])

  useEffect(() => {
    const current = document.documentElement.getAttribute('data-theme')
    if (theme === current || preventThemeChange) return
    setShouldAnimatePageBg(true)
    document.documentElement.setAttribute('data-theme', theme)
  }, [theme, preventThemeChange])

  useEffect(() => {})

  useEffect(() => {
    document.documentElement.setAttribute('data-fullscreen', false)
  }, [])

  useEffect(() => {
    const increasedVertical = usingVerticalScroll > lastUsingVertical.current
    const increasedHorizontal = usingHorizontalScroll > lastUsingHorizontal.current
    if (increasedVertical) setCurrentLayout('vertical')
    if (increasedHorizontal) setCurrentLayout('horizontal')
    lastUsingHorizontal.current = usingHorizontalScroll
    lastUsingVertical.current = usingVerticalScroll
  }, [usingHorizontalScroll, usingVerticalScroll, lastUsingHorizontal.current, lastUsingVertical.current])

  useEffect(() => {
    const onResize = (e) => {
      console.log(e.target.innerWidth)
      const isDesktop = e.target.innerWidth >= parseInt(desktop)
      if (isDesktop && usingHorizontalScroll) setCurrentLayout('horizontal')
      if (!isDesktop) setCurrentLayout('vertical')
    }

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [usingHorizontalScroll])

  useLayoutEffect(() => {
    document.documentElement.classList.toggle('has-horizontal-scroller', currentLayout === 'horizontal')
  }, [currentLayout])

  return null
}
