// https://www.w3.org/TR/2011/WD-css3-2d-transforms-20111215/#matrix-decomposition
// matrix(scaleX, skewX, skewY, scaleY, translateX, translateY);
const getComputedMatrix = (el) => {
  const st = window.getComputedStyle(el)
  const tr = st.getPropertyValue('transform') || ''
  const values = {
    scaleX: 0,
    skewX: 0,
    skewY: 0,
    scaleY: 0,
    translateX: 0,
    translateY: 0,
  }
  if (tr !== 'none') {
    const valuesArr = tr.split('(')[1].split(')')[0].split(',')
    values.scaleX = parseInt(valuesArr[0])
    values.skewX = parseInt(valuesArr[1])
    values.skewY = parseInt(valuesArr[2])
    values.scaleY = parseInt(valuesArr[3])
    values.translateX = parseInt(valuesArr[4])
    values.translateY = parseInt(valuesArr[5])
  }
  return values
}

export default getComputedMatrix
