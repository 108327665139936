import { Children, cloneElement, useCallback } from 'react'

import useUIContext from 'context/ui'

const CursorHover = ({ children, icon }) => {
  const setCursorIcon = useUIContext((s) => s.setCursorIcon)

  const onMouseEnter = useCallback(() => setCursorIcon(icon), [icon, setCursorIcon])
  const onMouseMove = useCallback(() => setCursorIcon(icon), [icon, setCursorIcon])
  const onMouseLeave = useCallback(() => setCursorIcon(null), [icon, setCursorIcon])

  Children.only(children)
  return Children.map(children, (child) => cloneElement(child, { onMouseEnter, onMouseLeave, onMouseMove }))
}

export default CursorHover
