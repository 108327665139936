const linkResolver = ({ type, href, uid, tags, ...props }) => {
  if (type === 'home_page') return '/'
  if (type === 'about_page') return '/about/'
  if (type === 'contact_page') return '/contact/'
  if (type === 'originals_page') return '/originals/'
  if (type === 'work_page') return '/work/'
  if (type === 'blog_page') return '/blog/'

  // project template is routed based on tag
  if (type === 'project_page') return `/work/${uid}/`
  if (type === 'original_page') return `/originals/${uid}/`
  if (type === 'generic_page') return `/${uid}/`
  if (type === 'blog_post') return `/blog/${uid}/`

  if (type === 'error_page') return '/404'
  return '/'
}

module.exports = { linkResolver }
